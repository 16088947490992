import React,  {Component} from 'react';

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import Posts from './components/Posts/Posts';
import Navbar from './components/Navbar/Navbar'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar/>
        <Posts/>
      </div>
    );
  }
}

export default App;
