import React from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

const post = (props) => (
    <Row className="justify-content-md-center mt-2 mb-2">
        <Card>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{props.author}</Card.Subtitle>
                <Card.Text>{props.content}</Card.Text>
            </Card.Body>
        </Card>
    </Row>
    
);

export default post;