import React from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

const navbar = () => (
    <Navbar bg="dark" variant = "dark" expand="lg">
        <Container>
            <Navbar.Brand href="#home">CloudflareConnection</Navbar.Brand>
        </Container>
    </Navbar>
    
);

export default navbar;