import axios from 'axios';

// Cloudflare worker BaseURL
const instance = axios.create({
    baseURL: 'https://worker.amrit-singh-rana.workers.dev'
});

// instance.defaults.headers.common['Authorization'] = "AUTH TOKEN FROM INSTANCE";
// instance.defaults.headers.common['Content-Type'] = "application/json";
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

export default instance;