import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

import axios from '../../axios';

import Post from './Post/Post';

class Posts extends Component {

    state = {
        posts:[],
        error: false
    }

    // Fetch posts when component loads
    componentDidMount() {
        axios.get('/posts')
            .then(response => {
                const posts = response.data;
                console.log(posts);
                this.setState({posts: posts});
            })
            .catch(error => {
                this.setState({error: true});
            });
    }

    render() {
        let posts = <p style={{textAlign: 'center'}}>Something went wrong!</p>;

        if (!this.state.error) {
            posts = this.state.posts.map(post => {
                return <Post 
                    key={post.id} 
                    title={post.title} 
                    author={post.username}
                    content={post.content}
                    />;
            });
        }

        return(
            <Container>
                {posts}
            </Container>
            
        );
    }
}

export default Posts;